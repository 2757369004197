export default [
  "./app.vue",
  "./pages/**/*.{vue,js,ts,jsx,tsx}",
  "./layouts/**/*.{vue,js,ts,jsx,tsx}",
  "./components/**/*.{vue,js,ts,jsx,tsx}",
  "H:/ssd2/vkusilosos-web-main/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "H:/ssd2/vkusilosos-web-main/components/global/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "H:/ssd2/vkusilosos-web-main/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "H:/ssd2/vkusilosos-web-main/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "H:/ssd2/vkusilosos-web-main/pages/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "H:/ssd2/vkusilosos-web-main/plugins/**/*.{js,ts,mjs}",
  "H:/ssd2/vkusilosos-web-main/composables/**/**/*.{js,ts,mjs}",
  "H:/ssd2/vkusilosos-web-main/composables/**/*.{js,ts,mjs}",
  "H:/ssd2/vkusilosos-web-main/utils/**/*.{js,ts,mjs}",
  "H:/ssd2/vkusilosos-web-main/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "H:/ssd2/vkusilosos-web-main/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "H:/ssd2/vkusilosos-web-main/app.config.{js,ts,mjs}"
]