import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSetDataDialog = defineStore(
  'setDataDialog',
  () => {
    const isOpen = ref(false)

    const open = () => {
      isOpen.value = true
    }
    const close = () => {
      isOpen.value = false
    }

    return { isOpen, open, close }
  },
)
