const _0 = "0px"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "0.75rem"
const _4 = "1rem"
const _5 = "1.25rem"
const _6 = "1.5rem"
const _7 = "1.75rem"
const _8 = "2rem"
const _9 = "2.25rem"
const _10 = "2.5rem"
const _11 = "2.75rem"
const _12 = "3rem"
const _14 = "3.5rem"
const _16 = "4rem"
const _18 = "4.5rem"
const _20 = "5rem"
const _24 = "6rem"
const _28 = "7rem"
const _32 = "8rem"
const _36 = "9rem"
const _40 = "10rem"
const _44 = "11rem"
const _48 = "12rem"
const _52 = "13rem"
const _56 = "14rem"
const _60 = "15rem"
const _64 = "16rem"
const _72 = "18rem"
const _80 = "20rem"
const _96 = "24rem"
const _px = "1px"
const _full = "100%"
const _screen = "100vh"
const _svh = "100svh"
const _lvh = "100lvh"
const _dvh = "100dvh"
const _min = "min-content"
const _max = "max-content"
const _fit = "fit-content"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "14": _14, "16": _16, "18": _18, "20": _20, "24": _24, "28": _28, "32": _32, "36": _36, "40": _40, "44": _44, "48": _48, "52": _52, "56": _56, "60": _60, "64": _64, "72": _72, "80": _80, "96": _96, "px": _px, "full": _full, "screen": _screen, "svh": _svh, "lvh": _lvh, "dvh": _dvh, "min": _min, "max": _max, "fit": _fit, "0.5": "0.125rem", "1.5": "0.375rem", "2.5": "0.625rem", "3.5": "0.875rem",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _14, _16, _18, _20, _24, _28, _32, _36, _40, _44, _48, _52, _56, _60, _64, _72, _80, _96, _px, _full, _screen, _svh, _lvh, _dvh, _min, _max, _fit }