<template>
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6382 3.96974C14.1206 3.51913 14.1206 2.78856 13.6382 2.33795C13.1558 1.88735 12.3736 1.88735 11.8912 2.33795L4.52941 9.21437L2.10878 6.95334C1.62637 6.50273 0.844222 6.50273 0.361809 6.95334C-0.120603 7.40394 -0.120603 8.13452 0.361809 8.58512L3.65593 11.662C4.13834 12.1126 4.92048 12.1126 5.4029 11.662L13.6382 3.96974Z"
      fill="currentColor"
    />
  </svg>
</template>
