const _transparent = "transparent"
const _current = "currentColor"
const _white = "#FFFFFF"
const _whitegray = "#FDFDFD"
const _black = "#252321"
const _gray = "#f1f1f1"
const _red = "#EB001B"
const _orange = {"100":"#FADEC3","200":"#F4973C","400":"#FF7C33","600":"#EA5430"}
const _blue = {"100":"#E6F0F8"}
const _yellowgreen = {"100":"#F0EDBA"}
const _yellow = {"100":"#FEEDB1"}
const config = { "transparent": _transparent, "current": _current, "white": _white, "whitegray": _whitegray, "black": _black, "gray": _gray, "red": _red, "orange": _orange, "blue": _blue, "yellowgreen": _yellowgreen, "yellow": _yellow,  }
export { config as default, _transparent, _current, _white, _whitegray, _black, _gray, _red, _orange, _blue, _yellowgreen, _yellow }